import React, { useState, useRef, useEffect, useCallback } from 'react';
import lottie from 'lottie-web';

import useIntlT from '../utils/useIntlT';

import styles from './hero-section.module.css';

const Owl = ({ lastTriggered }) => {
  const t = useIntlT();
  const div = useRef(null);
  const anim = useRef(null);

  useEffect(() => {
    const lottieAnim = lottie.loadAnimation({
      container: div.current,
      renderer: 'svg',
      loop: false,
      autoplay: false,
      animationData: JSON.parse(t`owlJson`),
    });
    anim.current = lottieAnim;

    const firstPlay = setTimeout(() => {
      if (anim.current.isPaused) {
        anim.current.goToAndPlay(0);
      }
    }, Math.random() * 1200 + 800);

    return () => {
      clearTimeout(firstPlay);
      anim.current = null;
      lottieAnim.destroy();
    };
  }, [t]);

  useEffect(() => {
    if (anim.current && anim.current.isPaused) {
      anim.current.goToAndPlay(0);
    }
  }, [lastTriggered]);

  return <div className={`${styles.owl} pointer-events-none`} ref={div} />;
};

export default () => {
  const t = useIntlT();

  const [lastMeiyuTriggered, setLastMeiyuTriggered] = useState(Date.now());
  const onMouseEnterMeiyu = useCallback(() => {
    setLastMeiyuTriggered(Date.now())
  }, []);

  return (
    <div className={`${styles.section} overflow-hidden`}>
      <div className={`${styles.container} p-6 pb-3 mx-auto`}>
        <div className="flex justify-center md:justify-start">
          <div
            className={`${styles.title} border-theme-default border-4 rounded bg-white flex-grow md:flex-grow-0 p-6 pb-0 md:pb-6 text-center md:text-left`}
          >
            <h1 className="hidden md:block text-5xl font-bold">{t`heroTitle`}</h1>
            <h1 className="md:hidden text-3xl font-bold">{t`heroTitleMobile`}</h1>
            <h3 className="md:text-2xl font-medium">{t`heroSub`}</h3>
            <a
              className={`${styles.btn} inline-block bg-theme-default hover:bg-theme-hover active:bg-theme-active rounded-btn text-white font-medium mt-2 p-3 px-4`}
              href={t`inviteLinebot`}
            >
              {t`heroBtn`}
            </a>
          </div>
          <div className="hidden md:block">
            <img
              className={styles.qrcode}
              alt={t`heroQrcodeAlt`}
              src={t`heroQrcodeSrc`}
            />
          </div>
        </div>
        <div className={`${styles.main} mt-12 relative`}>
          <div className={styles.height} />
          <Owl lastTriggered={lastMeiyuTriggered} />
          { /* eslint-disable-next-line jsx-a11y/no-static-element-interactions */ }
          <div onMouseEnter={onMouseEnterMeiyu} className={styles.meiyu} />
        </div>
      </div>
    </div>
  );
};
