import React, { useState, useCallback } from 'react';
import collapseUpSvg from '../assets/collapse-up.svg';
import collapseDownSvg from '../assets/collapse-down.svg';

import useIntlT from '../utils/useIntlT';

export default ({ question, answer, initOpened }) => {
  const t = useIntlT();
  const [opened, setOpened] = useState(initOpened || false);
  const onQuestionClick = useCallback(
    e => {
      if (e.keyCode && e.keyCode !== 13) return;
      setOpened(o => !o);
      e.preventDefault();
    },
    [setOpened]
  );

  return (
    <div className="my-3 border-theme-2 border-2 rounded bg-white p-6">
      <div
        className="px-2 py-3 flex justify-between cursor-pointer"
        role="button"
        tabIndex={0}
        onKeyDown={onQuestionClick}
        onClick={onQuestionClick}
      >
        <h3 className="text-lg font-bold">{question}</h3>
        <img
          alt={opened ? t`questionAnswerCloseAlt` : t`questionAnswerOpenAlt`}
          src={opened ? collapseUpSvg : collapseDownSvg}
        />
      </div>
      {opened && (
        <p className="border-theme-2 border-t mt-3 pt-6 p-2">{answer}</p>
      )}
    </div>
  );
};
