const { cooperateIds } = require('../../utils/assets.js');

module.exports = {
  genCooperateMsgs: brands => ({
    cooperatorCount: brands.length.toString(),
    ...Object.fromEntries(
      brands.flatMap(({ require: requirePath, src, alt, href }, index) => {
        const ids = cooperateIds(index.toString());

        return [
          [ids.src, src],
          [ids.alt, alt],
          [ids.href, href],
        ];
      })
    ),
  }),
};
