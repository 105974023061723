import React from 'react';

import LanguageSwitch from './LanguageSwitch';

import useIntlT from '../utils/useIntlT';

import styles from './header.module.css';

export default ({ langProps }) => {
  const t = useIntlT();

  return (
    <div className="w-full text-white bg-theme-active">
      <div className="flex items-center justify-between max-w-screen-xl mx-auto p-3 md:px-6 lg:px-8">
        <div className="m-2 md:mr-10">
          <img className={styles.brand} alt={t`brandAlt`} src={t`brandSrc`} />
        </div>
        <div className="flex-1 mx-2 overflow-x-auto">
          <a href={t`communityHref`} className="text-sm md:text-lg pr-3">
            {t`community`}
          </a>
          <a href={t`reportHref`} className="text-sm md:text-lg pr-3">
            {t`report`}
          </a>
        </div>
        <LanguageSwitch {...langProps} />
      </div>
    </div>
  );
};
