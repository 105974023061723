import React from 'react';

import useIntlT from '../utils/useIntlT';
import { demoSrcIds } from '../utils/assets';

import styles from './demo-section.module.css';

const DemoPicture = ({ name, alt, ...props }) => {
  const t = useIntlT();
  const ids = demoSrcIds(name);

  return (
    <picture>
      <source media="(min-width: 1280px)" src={t(ids.x1)} />
      <source
        media="(min-width: 768px)"
        srcSet={`${t(ids.x1)} 1x, ${t(ids.x2)} 2x`}
      />
      <source srcSet={`${t(ids.mobileX1)} 1x, ${t(ids.mobileX2)} 2x`} />
      <img alt={alt} src={t(ids.x1)} {...props} />
    </picture>
  );
};

export default () => {
  const t = useIntlT();

  return (
    <div className="max-w-screen-lg py-3 md:p-6 mx-auto">
      <div className="my-4 relative">
        <DemoPicture className="w-full" alt={t`demo01Title`} name="01" />
        <div
          className={`${styles.demo01} flex flex-col justify-center mx-6 my-10 md:m-0 md:absolute`}
        >
          <h3 className="text-2xl font-bold">{t`demo01Title`}</h3>
          <p className="mt-2">{t`demo01Description`}</p>
        </div>
      </div>
      <div className="my-4 relative">
        <DemoPicture className="w-full" alt={t`demo02Title`} name="02" />
        <div
          className={`${styles.demo02} flex flex-col justify-center mx-6 my-10 md:m-0 md:absolute`}
        >
          <h3 className="text-2xl font-bold">{t`demo02Title`}</h3>
          <p className="mt-2">{t`demo02Description`}</p>
        </div>
      </div>
    </div>
  );
};
