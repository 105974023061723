const { demoSrcIds } = require('../../utils/assets.js');
const { genCooperateMsgs } = require('./utils.js');

module.exports = {
  ...require('./shared.js'),

  title: 'Whoscall Checkがメッセージの検証を手伝います。',
  metaUrl: 'https://www.checkcheck.me/ja',
  metaDescription:
    'シェアされた文章、実はニセ情報？知らずにデマを拡散していませんか？怪しいLINEアカウントを友だちに追加していませんか？メッセージ内に知らない電話番号が書かれていませんか？自分のメール情報が漏洩しているかも？\n\n確認したい情報が有れば、Whoscall Checkにおまかせ！',
  metaImageSrc: require('./assets/demo.en/AM_web_900x452.png'),
  metaKeywords:
    'WhoscallCheck, fact-checking, chatbot, 訊息查證, 謠言查證, 聊天機器人, LINE',

  brandSrc: require('./assets/brand.ja.svg'),
  brandAlt: 'Whoscall Check',
  navTitle: 'Whoscall Check',
  community: 'Facebookページ',
  communityHref: 'https://www.facebook.com/groups/2034059996670763',
  report: 'フィードバック',
  reportHref:
    'https://airtable.com/shry4dLS3v3AE68Gb',

  heroTitle: 'Whoscall Check',
  heroTitleMobile: 'Whoscall Check',
  heroSub: 'メッセージの検証を手伝います。\nたった1秒で疑わしいメッセージを検出、\nWhoscall Checkがお知らせします。',
  heroBtn: 'Whoscall Checkを友だちに追加する',
  inviteLinebot: 'https://lin.ee/hEcGBxO',
  heroQrcodeSrc: require('./assets/hero-qrcode.ja.svg'),
  heroQrcodeAlt: 'Whoscall Checkを友だちに追加する',
  owlJson: JSON.stringify(require('./assets/hero-owl.en.json')),

  introImgSrc: require('./assets/intro.en.svg'),
  introImgAlt: 'intro-img',
  introLines: 'シェアされた文章、実はニセ情報？知らずにデマを拡散していませんか？怪しいLINEアカウントを友だちに追加していませんか？メッセージ内に知らない電話番号が書かれていませんか？自分のメール情報が漏洩しているかも？\n確認したい情報が有れば、Whoscall Checkにおまかせ！',

  [demoSrcIds('01').x1]: require('./assets/demo.en/AM_web_900x452.png'),
  [demoSrcIds('01').x2]: require('./assets/demo.en/AM_web_1800x904.png'),
  [demoSrcIds('01').mobileX1]: require('./assets/demo.en/AM_web_750x600.png'),
  [demoSrcIds('01').mobileX2]: require('./assets/demo.en/AM_web_750x600.png'),
  demo01Title: 'リアルタイムで分かる',
  demo01Description: 'Whoscall Checkをグループに招待すれば、もしも不審なメッセージがあった場合、即座に内容をチェック！関連情報が送信されますので、確認が可能です。不審なメッセージや情報にリアルタイムで応えてくれます。',
  [demoSrcIds('02').x1]: require('./assets/demo.en/02.png'),
  [demoSrcIds('02').x2]: require('./assets/demo.en/02@2x.png'),
  [demoSrcIds('02').mobileX1]: require('./assets/demo.en/02_mobile.png'),
  [demoSrcIds('02').mobileX2]: require('./assets/demo.en/02_mobile@2x.png'),
  demo02Title: '個別メッセージも可能',
  demo02Description: 'Whoscall Checkへは個別メッセージも可能です。グループ内では反応がなかったけど、情報の真偽が気になった場合、直接Whoscall Checkへメッセージを送って類似ケースの有無を確認することができます。もしも関連情報が照合できなかった場合、フィードバックすることで、検証チーム内のパートナーに検証してもらうことが可能です。ところで、あなたも検証チームに加わりませんか？一緒に不審なメッセージやデマの拡散を食い止めましょう！',
  featureTitle: '特徴',
  chatCheckTitle: '<strong>不審なメッセーを</strong>自動で検証',
  chatCheckDescription: '謎の健康法、エセ科学、疑わしいライフハック、癌に効く、癌を防ぐと謳う怪しい情報…統計によると、このようなメッセージを誰もが毎日２～５件も受信しています。これらは結局本当なのか？それとも巧妙なデマなのか？疑わしいメッセージはWhoscall Checkにお任せください。検証チームのデータベースからメッセージの信憑性を検証します！',
  chatCheckAppendix:
    '※データベース供給源：FIJ',
  callCheckTitle: '<strong>知らない電話番号を</strong>即識別',
  callCheckDescription:
    'グループチャットで送られてきた電話番号は安全でしょうか？Whoscall Checkなら16億件もの電話番号データベースを持つアプリ「Whoscall」から知らない番号を識別することが可能です。疑わしい番号にかけてしまい詐欺に遭う、なんてことを回避できます。',
  idCheckTitle: '<strong>不審なLINEアカウントを</strong>シャットアウト(Beta feature)',
  idCheckDescription:
    'LINEで知らない人からメッセージが来た、なんてことはありませんか？Whoscall Checkは台湾の警視庁が運営する「165全民防騙網」と協力することで、疑わしいLINE IDをリアルタイムで照合、危険なアカウントかどうかを確認し、安全・安心なチャットライフを提供します。',
  personalInfoTitle: '<strong>個人情報を</strong>安全に保護',
  personalInfoDescription: '昨今、ハッカーによる攻撃や、人為的なミスにより個人情報が流出する事件が増えています。Whoscall Checkは国際的に知名度の高い「Have I been pwned?」という個人情報保護サイトと提携しています。これにより、Whoscall CheckにあなたのEメールアドレスを打ち込むだけで、情報が漏洩・流出していないかが確認できます。アカウントの安全性を保つのに役立ち、あなたがすぐにパスワードを変更すべきかどうか判断する材料にもなります。',

  aboutTitle: 'About US',
  aboutLines:
    'Whoscall Checkは2018年12月に台湾のプログラマー徐曦により開発され、「メイユーおばさん」という名称のLINEチャットボットとしてリリースされました。当初は親戚や友人がコミュニケーションアプリ「LINE」内で受け取った疑わしいメッセージなどを検証することを目的に開発されました。やがてLINEグループ内での情報自動検証機能が人気を博したため、サービス内容も拡大・充実させ一般公開されました。そして現在、Whoscall CheckのLINE公式アカウントの友だち数は30万人を超え、なおも増え続けています。ユーザー増加に伴い、サービスに対して様々なニーズが生まれたため、これに応えるべく2020年からＷhoscallの開発を行うGogolookと協力し、信頼と安全をコンセプトに、情報検証や情報セキュリティを扱う各種プラットフォームとリンクすることで、より多くの情報の検証が可能となりました。\n目下、Whoscall CheckはGogolookの電話番号データベース、台湾警視庁の「165 全民防騙網」、国際的な個人情報保護サイト「Have I been pwned?」、MyGoPenなどを通して情報の確認・照合することで、ユーザーのネットリテラシー向上をサポートし、詐欺やデマの拡散を未然に防いでいます。',

  cooperatorTitle: 'パートナー',
  ...genCooperateMsgs([
    {
      src: require('./assets/cooperators/pwned.svg'),
      alt: 'have i been pwned?',
      href: 'https://haveibeenpwned.com/',
    },
    {
      src: require('./assets/cooperators/npa.png'),
      alt: '内政部警察署165全民詐欺防止ネットワーク',
      href: 'https://165.npa.gov.tw/',
    },
    {
      src: require('./assets/cooperators/whoscall.png'),
      alt: 'Whoscall',
      href: 'https://whoscall.com',
    },
  ]),

  questionAnswerTitle: 'FAQ',
  questionAnswerOpenAlt: '起動',
  questionAnswerCloseAlt: 'シャットダウン',
  questionAnswer01Q: 'Q1. Whoscall Checkをグループに招待できません。',
  questionAnswer01A: 'LINEの仕様により、チャットグループに追加できるチャットボットは1機のみとなっています。グループの友達リストを確認し、他のチャットボットを削除することでWhoscall Checkを招待することができます。',
  questionAnswer02Q: 'Q2.Whoscall Checkが質問に答えてくれません。',
  questionAnswer02A:
    'Whoscall Checkは、AI技術によってチャットルーム内の疑わしいメッセージを各種検証プラットフォーム内の情報と照合しています。Whoscall Checkが質問に答えなかった場合、それはそれらのプラットフォームに関連する情報がなかった、ということを意味します。Whoscall Checkへ個別メッセージを送って確認するかお願いします。',
  questionAnswer03Q: 'Q3. Whoscall Checkは個人情報の収集を行っていますか？',
  questionAnswer03A:
    'いいえ、行いません。LINEの規約を遵守するため、Whoscall Checkにはユーザーの個人情報を収集する機能がなく、収集は不可能です。',
  questionAnswer04Q: 'Q4. Whoscall Checkのせいでチャットの内容が誰かに見られたり、漏洩しませんか？',
  questionAnswer04A: 'チャットボットは、チャットルーム内のメッセージを自動的に照合することで疑わしいメッセージを識別し、データベースの検証結果に基づいて応答します。Whoscall Checkは、LINEのプライバシーポリシーに準拠し、メッセージの送信者を識別しないまま自動処理しており、疑わしいメッセージと関係のない会話であれば、検証効率を向上させるため、反応さえしません。\nWhoscall Checkは、すべてのユーザーにこのボットを理解・賛同頂いた上でご使用いただき、同時に疑わしいメッセージや詐欺からユーザーを全力で保護し、最も信頼に値するボットとして機能するよう最善を尽くします。',
  addMeiyuQRCode: require('./assets/add-meiyu-qrcode.ja.svg'),
  addMeiyuQRCodeAlt: 'スキャンしてWhoscall Checkを友だちに加える',
  addMeiyuBtn: 'タップして\nWhoscall Checkを\n友だちに\n加える',
  addMeiyuHref: 'https://lin.ee/hEcGBxO',
  addMeiyuAlt: 'Whoscall Checkを友だちに加える',
  poweredByGogolook: 'Powered By Gogolook',

  // links will be hidden if href is '#'
  copyright: '© 2022 Gogolook. All Rights Reserved.',
  privacyPolicy: 'プライバシーポリシー',
  privacyPolicyHref: '#',
  termsOfService: '利用規約',
  termsOfServiceHref: '#',
  followUs: 'フォローする',
  followUsLineHref:
    'https://lin.ee/hEcGBxO',
  followUsLineAlt: 'Line',
  followUsFacebookHref: '#',
  followUsFacebookAlt: 'Facebook',
  followUsTwitterHref: '#',
  followUsTwitterAlt: 'Twitter',
};
