import React from 'react';

import QuestionAnswerCollapse from './QuestionAnswerCollapse';

import useIntlT from '../utils/useIntlT';

export default () => {
  const t = useIntlT();

  return (
    <div id="fqa" className="max-w-screen-lg mx-auto p-4 md:p-8">
      <h2 className="mb-8 text-3xl font-bold text-center">{t`questionAnswerTitle`}</h2>
      <QuestionAnswerCollapse
        question={t`questionAnswer01Q`}
        answer={t`questionAnswer01A`}
        initOpened
      />
      <QuestionAnswerCollapse
        question={t`questionAnswer02Q`}
        answer={t`questionAnswer02A`}
      />
      <QuestionAnswerCollapse
        question={t`questionAnswer03Q`}
        answer={t`questionAnswer03A`}
      />
      <QuestionAnswerCollapse
        question={t`questionAnswer04Q`}
        answer={t`questionAnswer04A`}
      />
    </div>
  );
};
