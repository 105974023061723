const { demoSrcIds } = require('../../utils/assets.js');
const { genCooperateMsgs } = require('./utils.js');

module.exports = {
  ...require('./shared.js'),

  title: '美玉姨，訊息查證小幫手',
  metaUrl: 'https://www.checkcheck.me',
  metaDescription: '別人轉分享的訊息該相信嗎？可疑的LINE帳號要加為好友嗎？擔心訊息裡的陌生號碼撥出去有沒有問題？我的電子郵件有被外洩嗎？你想求證的訊息，交給美玉姨幫你問到底！',
  metaImageSrc: require('./assets/demo.tw/AM_web_900x452.png'),
  metaKeywords:
    '美玉姨, fact-checking, chatbot, 訊息查證, 謠言查證, 聊天機器人, LINE',

  brandSrc: require('./assets/brand.tw.svg'),
  brandAlt: '美玉姨',
  navTitle: '美玉姨',
  community: '社群',
  communityHref: 'https://www.facebook.com/groups/2034059996670763',
  report: '問題回報',
  reportHref:
    'https://airtable.com/shrfLkaQDs9OkjBDo',

  heroTitle: '美玉姨，訊息查證小幫手',
  heroTitleMobile: '美玉姨\n訊息查證小幫手',
  heroSub: '一秒偵測可疑訊息，阿姨報你知！',
  heroBtn: '邀請美玉姨當好友',
  inviteLinebot: 'https://line.me/R/ti/p/%40facter',
  heroQrcodeSrc: require('./assets/S_hero-qrcode.tw.png'),
  heroQrcodeAlt: '邀請美玉姨當好友',
  owlJson: JSON.stringify(require('./assets/hero-owl.tw.json')),

  introImgSrc: require('./assets/intro.tw.svg'),
  introImgAlt: 'intro-img',
  introLines: '別人轉分享的訊息該相信嗎？可疑的LINE帳號要加為好友嗎？擔心訊息裡的陌生號碼撥出去有沒有問題？我的電子郵件有被外洩嗎？\n你想求證的訊息，交給美玉姨幫你問到底！',
  [demoSrcIds('01').x1]: require('./assets/demo.tw/AM_web_900x452.png'),
  [demoSrcIds('01').x2]: require('./assets/demo.tw/AM_web_1800x904.png'),
  [demoSrcIds('01').mobileX1]: require('./assets/demo.tw/AM_web_750x600.png'),
  [demoSrcIds('01').mobileX2]: require('./assets/demo.tw/AM_web_750x600.png'),
  demo01Title: '群組即時回應',
  demo01Description: '將美玉姨加入群組聊天室後，若偵測到可疑的訊息，美玉姨可立即針對該訊息進行比對，並提供相關查證內容給你參考！幫助你第一時間對可疑訊息提高警覺！',
  [demoSrcIds('02').x1]: require('./assets/demo.tw/02.png'),
  [demoSrcIds('02').x2]: require('./assets/demo.tw/02@2x.png'),
  [demoSrcIds('02').mobileX1]: require('./assets/demo.tw/02_mobile.png'),
  [demoSrcIds('02').mobileX2]: require('./assets/demo.tw/02_mobile@2x.png'),
  demo02Title: '一對一訊息查詢',
  demo02Description:
    '美玉姨在群組沒回應？你可以一對一詢問美玉姨是否有類似可疑訊息。如果你手上的資訊還很新、沒有比對到相關內容，也可以回報給美玉姨，讓查核社群的夥伴幫助你查證內容。別人查的不放心？歡迎你一起加入事實查核的行列！',

  featureTitle: '主要特色',
  chatCheckTitle: '自動查證<strong>可疑訊息</strong>',
  chatCheckDescription:
    '養生撇步、奇妙的生活小秘訣、致癌防癌妙招...經過統計每個人每天平均都收到 2 ~ 5 個類似的訊息，到底是真的有效還是以訛傳訛？把可疑訊息告訴美玉姨，幫你在查核組織資料庫比對訊息真實性。',
  chatCheckAppendix:
    '＊查證資料庫來源：MyGoPen',
  callCheckTitle: '快速識別<strong>陌生電話號碼</strong>',
  callCheckDescription:
    '群組傳來的陌生電話號碼安全嗎？美玉姨自動幫你問問擁有16億號碼資料庫的陌生來電辨識App Whoscall，查詢陌生電話來源，避免你回撥詐騙電話被索取高額資費。',
  idCheckTitle: '遠離高風險<strong>陌生LINE帳號</strong>',
  idCheckDescription:
    'LINE收到陌生人的訊息可信嗎？美玉姨與刑事局165全民防騙網合作，將LINE ID傳給美玉姨，立刻為你查詢陌生ID是否被列為高風險詐騙帳號，聊天安全有保障。',
  personalInfoTitle: '安全防護<strong>個資帳號</strong>',
  personalInfoDescription:
    '最近又看到哪個網站被駭入的新聞嗎？美玉姨串接國際知名「Have I been pwned?」個資安全檢查平台，只要將電子郵件告訴美玉姨，立即確認你的個資有沒有遭到外洩，幫助你即時應變更改帳號密碼，保護帳號安全。',

  aboutTitle: 'About US',
  aboutLines:
    '美玉姨於 2018 年 12 月誕生，為台灣工程師徐曦所開發的 LINE 聊天機器人服務，以科技協力者為初衷，協助親友查證從通訊軟體 LINE 上接收到的可疑訊息，由於群組自動查證功能廣受歡迎，進而擴大服務範圍，全面開放民眾查證可疑訊息，至今美玉姨LINE官方帳號已累績超過 30 萬好友數。\n隨著使用者持續增加，產生更多不同的訊息查證需求，為了能提供更完善的聊天機器人服務，美玉姨於 2020 年起與 Ｗhoscall 開發商 Gogolook 聯手，以信任、安全為核心理念，連結各方查核組織與資訊安全平台，幫助使用者更有效的取得多方資訊，培養全民訊息查證與識讀能力。\n目前美玉姨比對與查證訊息來源，包含 Gogolook 陌生號碼防詐資料庫、警政署刑事警察局 165 全民防騙網、國際個資安全性檢查網站 Have I been pwned？、MyGoPen等。',

  cooperatorTitle: '合作夥伴',
  ...genCooperateMsgs([
    {
      src: require('./assets/cooperators/pwned.svg'),
      alt: 'have i been pwned?',
      href: 'https://haveibeenpwned.com/',
    },
    {
      src: require('./assets/cooperators/mygopen.svg'),
      alt: 'MyGoPen',
      href: 'https://www.mygopen.com/',
    },
    {
      src: require('./assets/cooperators/npa.png'),
      alt: '內政部警政署165全民防騙網',
      href: 'https://165.npa.gov.tw/',
    },
    {
      src: require('./assets/cooperators/whoscall.png'),
      alt: 'Whoscall',
      href: 'https://whoscall.com',
    },
  ]),

  questionAnswerTitle: '問答集',
  questionAnswerOpenAlt: '開啟',
  questionAnswerCloseAlt: '關閉',
  questionAnswer01Q: 'Q1. 為什麼無法邀請美玉姨加入群組？',
  questionAnswer01A:
    '根據通訊軟體LINE規範，一個聊天群組內僅能加入一個聊天機器人。您可以檢視一下目前群組的好友列表，並將其他聊天機器人移出群組，再重新加入美玉姨。',
  questionAnswer02Q: 'Q2. 為什麼美玉姨都沒有回應我的問題？',
  questionAnswer02A:
    '美玉姨透過AI技術，自動比對聊天室的可疑訊息與各方查核組織的查核結果，將相關查證訊息提供您參考。若美玉姨未回答您的問題，表示目前沒有相關查核結果，請透過美玉姨一對一聊天視窗回報。',
  questionAnswer03Q: 'Q3. 美玉姨會收集我的個人資料嗎？',
  questionAnswer03A:
    '不會，美玉姨不會也無法收集任何使用者的個人資料，完全遵守LINE相關規範。',
  questionAnswer04Q: 'Q4. 美玉姨會傾聽我們的對話嗎？',
  questionAnswer04A:
    '聊天機器人透過自動比對聊天室訊息，有效辨識可疑訊息並依據資料庫查證結果提供回應。美玉姨完全遵守LINE的隱私規定，以去識別化的方式處理所有訊息，意即聊天機器人無法識別訊息的傳送者，同時也會盡可能忽略與可疑訊息無關的日常對話，以提升最大的查證效率。\n美玉姨會盡充分揭露的責任，確保每一位使用者是知情且同意的狀態下使用功能此機器人，同時盡最大的努力保護使用者受到可疑訊息以及詐騙的危害，成為最值得信賴的查證機器人。',

  addMeiyuQRCode: require('./assets/S_hero-qrcode.tw.png'),
  addMeiyuQRCodeAlt: '掃描加美玉 Line 好友',
  addMeiyuBtn: '按我加美玉\nLine好友',
  addMeiyuHref: 'https://lin.ee/1ak3owX',
  addMeiyuAlt: '加美玉 Line 好友',
  poweredByGogolook: 'Powered By Gogolook',

  // links will be hidden if href is '#'
  copyright: '© 2022 Gogolook. All Rights Reserved.',
  privacyPolicy: '隱私權政策',
  privacyPolicyHref: '#',
  termsOfService: '服務條款',
  termsOfServiceHref: '#',
  followUs: 'Follow us:',
  followUsLineHref:
    'https://timeline.line.me/user/_dQJdHYe01VUR392PrXyykPrYjwkT0DdwaBIuQaw',
  followUsLineAlt: 'Line',
  followUsFacebookHref: '#',
  followUsFacebookAlt: 'Facebook',
  followUsTwitterHref: '#',
  followUsTwitterAlt: 'Twitter',
};
