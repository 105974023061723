import React from 'react';

import useIntlT from '../utils/useIntlT';
import { cooperateIds } from '../utils/assets';

export default () => {
  const t = useIntlT();

  return (
    <div className="max-w-screen-xl mx-auto p-4 md:p-8 text-center">
      <h2 className="mb-8 text-3xl font-bold">{t`cooperatorTitle`}</h2>
      {Array.from(Array(parseInt(t`cooperatorCount`)).keys()).map(index => {
        const ids = cooperateIds(index);
        return (
          <a key={index} target="_blank" rel="noreferrer" href={t(ids.href)}>
            <img
              className="inline-block mx-8 my-6 max-w-xxs md:max-w-none"
              alt={t(ids.alt)}
              src={t(ids.src)}
            />
          </a>
        );
      })}
    </div>
  );
};
