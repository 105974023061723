import React, { useMemo } from 'react';
import { getCurrentLangKey } from 'ptz-i18n';
import { useStaticQuery, graphql, withPrefix } from 'gatsby';
import { IntlProvider } from 'react-intl';
import 'intl';

import HtmlHead from '../components/HtmlHead';
import Header from '../components/Header';

import * as messages from '../data/messages';
import 'intl/locale-data/jsonp/en';

import './index.css';

const getLangProps = (languages, location) => {
  const { langs, defaultLangKey } = languages;
  const currentPath = location.pathname.replace(
    new RegExp(`^${withPrefix('/')}`),
    '/'
  );
  const langKey = getCurrentLangKey(langs, defaultLangKey, currentPath);

  return { langKey, currentPath, languages };
};

const Layout = ({ children, location, i18nMessages }) => {
  const data = useStaticQuery(graphql`
    query LayoutQuery {
      site {
        siteMetadata {
          languages {
            defaultLangKey
            langs
          }
        }
      }
    }
  `);

  const langProps = useMemo(
    () => getLangProps(data.site.siteMetadata.languages, location),
    [data.site.siteMetadata.languages, location]
  );

  return (
    <IntlProvider locale="en" messages={messages[langProps.langKey]}>
      <>
        <HtmlHead />
        <Header langProps={langProps} />
        <div className="text-theme-default">{children}</div>
      </>
    </IntlProvider>
  );
};

export default Layout;
