import React from 'react';

import useIntlT from '../utils/useIntlT';

import styles from './intro-section.module.css';

export default () => {
  const t = useIntlT();

  return (
    <div className="max-w-screen-lg mx-auto p-4 md:p-8">
      <img
        className="m-8 md:mt-20 mx-auto"
        alt={t`introImgAlt`}
        src={t`introImgSrc`}
      />
      <h2
        className={`${styles.lines} text-xl font-medium text-center`}
      >{t`introLines`}</h2>
    </div>
  );
};
