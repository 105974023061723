import React from 'react';

import useIntlT from '../utils/useIntlT';

import styles from './about-section.module.css';

export default () => {
  const t = useIntlT();

  return (
    <div className="bg-theme-1">
      <div className="max-w-screen-lg mx-auto p-4 md:p-8">
        <h2 className="mb-8 text-3xl font-bold text-center">{t`aboutTitle`}</h2>
        <p
          className={`${styles.lines} font-normal text-center`}
        >{t`aboutLines`}</p>
      </div>
    </div>
  );
};
