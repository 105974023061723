import React, { useState, useMemo } from 'react';
import { withPrefix } from 'gatsby';
import { getLangs, getUrlForLang } from 'ptz-i18n';

import useIntlT from '../utils/useIntlT';

import styles from './language-switch.module.css';

import globeSvg from '../assets/globe.svg';

const getLangMenu = (langKey, currentPath, langs, defaultLangKey) => {
  const homeLink = `/${langKey}/`.replace(`/${defaultLangKey}/`, '/');
  return getLangs(langs, langKey, getUrlForLang(homeLink, currentPath)).map(
    item => ({
      ...item,
      link: item.link.replace(`/${defaultLangKey}/`, '/'),
    })
  );
};

export default ({
  langKey,
  currentPath,
  languages: { langs, defaultLangKey },
}) => {
  const t = useIntlT();

  const langsMenu = useMemo(
    () => getLangMenu(langKey, currentPath, langs, defaultLangKey),
    [langKey, currentPath, langs, defaultLangKey]
  );
  const [opened, setOpened] = useState(false);
  const [clickedOpened, setClickedOpened] = useState(false);

  return (
    <span
      className="cursor-pointer text-sm"
      role="button"
      tabIndex={0}
      onTouchEnd={e => {
        e.preventDefault();
        setClickedOpened(!clickedOpened);
      }}
      onMouseEnter={() => setOpened(true)}
      onMouseLeave={() => setOpened(false)}
      onClick={() => setClickedOpened(!clickedOpened)}
      onKeyDown={e => e.keyCode === 13 && setClickedOpened(!clickedOpened)}
    >
      <img alt={t`languageSwitch`} className="inline mx-2" src={globeSvg} />
      <span className="hidden md:inline">{t`languageSwitch`}</span>
      <div
        className={`${
          opened || clickedOpened ? '' : 'hidden'
        } relative w-0 h-0`}
      >
        <div className={styles.area} />
        <div
          className={`${styles.bubble} border-theme-default border-2 rounded bg-white text-theme-default px-4 py-3 shadow-lg`}
          onTouchEnd={e => {
            e.stopPropagation();
          }}
        >
          {langsMenu.map(({ langKey, link, selected }) => (
            <a
              key={langKey}
              className={`block my-2 text-center text-sm ${
                selected ? 'font-black' : ''
              }`}
              href={withPrefix(link)}
              alt={t(`languageSwitch-${langKey}`)}
            >
              {t(`languageSwitch-${langKey}`)}
            </a>
          ))}
        </div>
      </div>
    </span>
  );
};
