import React, { useCallback } from 'react';
import { useIntl } from 'react-intl';

const defaultOpt = {
  wraperTag: 'span',
  insertBr: true,
};

export default () => {
  const { formatMessage } = useIntl();

  return useCallback(
    (id, options = {}) => {
      const message = formatMessage({ id });
      if (message.includes('\n')) {
        const lines = message.split('\n');
        const mergedOpt = { ...defaultOpt, ...options };
        return [
          ...lines
            .slice(0, -1)
            .flatMap((l, i) => [
              React.createElement(mergedOpt.wraperTag, { key: `${i}-s` }, [l]),
              ...(mergedOpt.insertBr ? [<br key={`${i}-b`} />] : []),
            ]),
          <span key="last">{lines[lines.length - 1]}</span>,
        ];
      } else {
        return message;
      }
    },
    [formatMessage]
  );
};
