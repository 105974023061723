const { demoSrcIds } = require('../../utils/assets.js');
const { genCooperateMsgs } = require('./utils.js');

module.exports = {
  ...require('./shared.js'),

  title: 'Auntie Meiyu, your trusted fact-checking confidant',
  metaUrl: 'https://www.checkcheck.me/en',
  metaDescription:
    'How can you discern fake news and rumours from credible information? Getting lots of unknown friend requests? Worried about calling out to unknown numbers in messages? Let Auntie Meiyu help you!',
  metaImageSrc: require('./assets/demo.en/AM_web_900x452.png'),
  metaKeywords: 'fact-checking, chatbot, LINE',

  brandSrc: require('./assets/brand.en.svg'),
  brandAlt: 'Auntie Meiyu',
  navTitle: 'Auntie Meiyu',
  community: 'Community',
  communityHref: 'https://www.facebook.com/groups/2034059996670763',
  report: 'Report',
  reportHref:
    'https://airtable.com/shrfLkaQDs9OkjBDo?fbclid=IwAR3GhIqQ-Ct6vJZssc-PoJqjv8VJN-U3ON8fEPPlwbzzY2qsdShY3PxIy4s',

  heroTitle: 'Auntie Meiyu',
  heroTitleMobile: 'Auntie Meiyu',
  heroSub:
    'your trusted fact-checking confidant, helping you to identify fake news',
  heroBtn: 'Add on LINE',
  inviteLinebot: 'https://line.me/R/ti/p/%40facter',
  heroQrcodeSrc: require('./assets/hero-qrcode.en.svg'),
  heroQrcodeAlt: 'Add on LINE',
  owlJson: JSON.stringify(require('./assets/hero-owl.en.json')),

  introImgSrc: require('./assets/intro.en.svg'),
  introImgAlt: 'intro-img',
  introLines:
    'How can you discern fake news and rumours from credible information?\nGetting lots of unknown friend requests? Worried about calling out to unknown numbers in messages?\nLet Auntie Meiyu help you!',

  [demoSrcIds('01').x1]: require('./assets/demo.en/AM_web_900x452.png'),
  [demoSrcIds('01').x2]: require('./assets/demo.en/AM_web_1800x904.png'),
  [demoSrcIds('01').mobileX1]: require('./assets/demo.en/AM_web_750x600.png'),
  [demoSrcIds('01').mobileX2]: require('./assets/demo.en/AM_web_750x600.png'),
  demo01Title: 'Group Chat Real-time Response',
  demo01Description:
    'Add Auntie Meiyu into your group chat! She will silently lurk in the chat room and never interrupts your conversation. Auntie Meiyu will speak up only when she notices some questionable information being posted. She then provides verified information and helps you identify fake news.',
  [demoSrcIds('02').x1]: require('./assets/demo.en/02.png'),
  [demoSrcIds('02').x2]: require('./assets/demo.en/02@2x.png'),
  [demoSrcIds('02').mobileX1]: require('./assets/demo.en/02_mobile.png'),
  [demoSrcIds('02').mobileX2]: require('./assets/demo.en/02_mobile@2x.png'),
  demo02Title: '1-1 private chat article search',
  demo02Description:
    'Not getting any replies from Auntie Meiyu? Ask Auntie Meiyu directly about any specific news or articles with a private message. If there are no related news or sources, you can also contribute by reporting the rumor along with any revalant information. ',

  featureTitle: 'Characteristics',
  chatCheckTitle: '<strong>Fact-check</strong>',
  chatCheckDescription:
    'Simply share a news article, or something that has been circulating on social media/messenging apps with Auntie Meiyu and immediately verify the claims against multiple databases.',
  chatCheckAppendix:
    '(Databases include: MyGoPen)',
  callCheckTitle: '<strong>Phone Number Check</strong>',
  callCheckDescription:
    "Ask Auntie Meiyu about any number, and she'll help you run a check against Whoscall's database of over 1.6 billion numbers. Never get scammed by fraud numbers again!",
  idCheckTitle: '<strong>Unknown LINE ID Verification</strong>',
  idCheckDescription:
    "Yet another friend request from an unknown person? Tell Auntie Meiyu, and she'll check with the Criminal Investigation Bureau database to see if the suspicious LINE ID is a scammer.",
  personalInfoTitle: '<strong>Info Leakage Check</strong>',
  personalInfoDescription:
    'The amount of personal info getting stolen from companies is ever increasing, and your info could be part of a data breach as well. By checking your e-mail with renowned data breach checking website「Have I been pwned?」, know instantly if your information has been compromised, and take appropiate action to change your credentials.',

  aboutTitle: 'About US',
  aboutLines:
    "Auntie Meiyu was created by Taiwanese Programmer Carol Hsu at the end of 2018. Starting out as a LINE chatbot, the main purpose of the project was to aid friends and family in discerning fake news and rumors that are forever prevalent within the messenging app. After being recevied with great enthusiasim, the service was soon released to the public as well, and as of today enjoys a presence of over 300,000 users.\nAs the service exploded in size, the needed infrastructure and operational support ballooned as well. Recognizing the need for a reputable partner, Auntie Meiyu joined hands with Gogolook, the developer behind popular caller ID app Whoscall in 2020. With a united goal of providing safety and trust to users, the Auntie Meiyu service expanded it's offerings to offer even more complete protection to the general public.\nCurrently, the data sources that Auntie Meiyu draws from includes Gogolook's own phone number database, the Criminal Investigation Bureau's national database, renowned data breach checking website「Have I been pwned?」and fact-checker MyGoPen.",

  cooperatorTitle: 'Cooperators',
  ...genCooperateMsgs([
    {
      src: require('./assets/cooperators/pwned.svg'),
      alt: 'have i been pwned?',
      href: 'https://haveibeenpwned.com/',
    },
    {
      src: require('./assets/cooperators/mygopen.svg'),
      alt: 'MyGoPen',
      href: 'https://www.mygopen.com/',
    },
    {
      src: require('./assets/cooperators/npa.png'),
      alt: 'National Police Agency,Ministry of the Interior',
      href: 'https://165.npa.gov.tw/',
    },
    {
      src: require('./assets/cooperators/whoscall.png'),
      alt: 'Whoscall',
      href: 'https://whoscall.com',
    },
  ]),

  questionAnswerTitle: 'Q&A',
  questionAnswerOpenAlt: 'Open',
  questionAnswerCloseAlt: 'Close',
  questionAnswer01Q: "Q1. I can't invite Auntie Meiyu into group chats",
  questionAnswer01A:
    'You might already have a chatbot in the current group. Check the members and delete other robot accounts. After that, you can add Auntie Meiyu into group chats.',
  questionAnswer02Q: "Q2. I can't get any response from Auntie Meiyu",
  questionAnswer02A:
    "In group chats, Auntie Meiyu only responds to the messages which look similar to rumors. If your messages have not been reported yet, Auntie Meiyu won't respond in the group chatroom.\nHowever, you can still ask Auntie Meiyu by sending the suspicious message one-on-one.",
  questionAnswer03Q: 'Q3. Will Auntie Meiyu collect my information?',
  questionAnswer03A:
    "No. Auntie Meiyu will not collect any user information. All the information Auntie Meiyu gets is under the privacy policy of LINE. Any chatbot cannot acquire users' personal information such as name or phone number.",
  questionAnswer04Q: 'Q4. Will Auntie Meiyu listen to our conversation?',
  questionAnswer04A:
    'To specify fake news, it is necessary to check the current sending messages. However, we try our best to ignore messages which are unrelated to rumors and give you a better user experience. We believe conversation privacy is important, and hope that every user is informed before using our product. We sincerely hope that you can trust our chatbot and protect yourself from hoaxes with us.',

  addMeiyuQRCode: require('./assets/add-meiyu-qrcode.en.svg'),
  addMeiyuQRCodeAlt: 'Add on LINE',
  addMeiyuBtn: 'Click and add\non LINE',
  addMeiyuHref: 'https://line.me/R/ti/p/%40facter',
  addMeiyuAlt: 'Add on LINE',
  poweredByGogolook: 'Powered By Gogolook',

  // links will be hidden if href is '#'
  copyright: '© 2022 Gogolook. All Rights Reserved.',
  privacyPolicy: 'Privacy Policy',
  privacyPolicyHref: '#',
  termsOfService: 'Terms of Service',
  termsOfServiceHref: '#',
  followUs: 'Follow us:',
  followUsLineHref:
    'https://timeline.line.me/user/_dQJdHYe01VUR392PrXyykPrYjwkT0DdwaBIuQaw',
  followUsLineAlt: 'Line',
  followUsFacebookHref: '#',
  followUsFacebookAlt: 'Facebook',
  followUsTwitterHref: '#',
  followUsTwitterAlt: 'Twitter',
};
