export const demoSrcIds = name => ({
  x1: `demoSrc-${name}-x1`,
  x2: `demoSrc-${name}-x2`,
  mobileX1: `demoSrc-${name}-mobile-x1`,
  mobileX2: `demoSrc-${name}-mobile-x2`,
});

export const cooperateIds = index => ({
  src: `cooperator-${index}-src`,
  alt: `cooperator-${index}-alt`,
  href: `cooperator-${index}-href`,
});
