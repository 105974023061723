import React from 'react';

import useIntlT from '../utils/useIntlT';

import lineSvg from '../assets/line.svg';
import facebookSvg from '../assets/facebook.svg';
import twitterSvg from '../assets/twitter.svg';

const Link = ({ intlHrefId, children, ...props }) => {
  const t = useIntlT();
  const href = t(intlHrefId);
  return (
    href !== '#' && (
      <a className="mx-2" href={href} {...props}>
        {children}
      </a>
    )
  );
};

export default () => {
  const t = useIntlT();

  return (
    <div className="bg-theme-1 pb-4">
      <div className="max-w-screen-lg mx-auto md:flex md:justify-between md:items-center px-6">
        <div>
          <span className="m-3">
            <a href={t`gogolookOfficialWebsite`} target="_blank" rel="noreferrer">{t`copyright`}</a>
          </span>
          <div className="py-2 block md:inline-block">
            <Link intlHrefId="privacyPolicyHref">{t`privacyPolicy`}</Link>
            <Link intlHrefId="termsOfServiceHref">{t`termsOfService`}</Link>
          </div>
        </div>
        <div>
          <span className="m-3">{t`followUs`}</span>
          <Link intlHrefId="followUsLineHref">
            <img
              className="inline-block"
              alt={t`followUsLineAlt`}
              src={lineSvg}
            />
          </Link>
          <Link intlHrefId="followUsFacebookHref">
            <img
              className="inline-block"
              alt={t`followUsFacebookAlt`}
              src={facebookSvg}
            />
          </Link>
          <Link intlHrefId="followUsTwitterHref">
            <img
              className="inline-block"
              alt={t`followUsTwitterAlt`}
              src={twitterSvg}
            />
          </Link>
        </div>
      </div>
    </div>
  );
};
