import React from 'react';
import Helmet from 'react-helmet';

import useIntlT from '../utils/useIntlT';

import favicon from '../assets/favicon.ico';

export default props => {
  const t = useIntlT();
  const metaImgUrl = `${t`baseUrl`}${t`metaImageSrc`}`;

  return (
    <Helmet>
      <title>{t`title`}</title>
      <link rel="icon" href={favicon} />
      <meta name="description" content={t`metaDescription`} />
      <meta name="keywords" content={t`metaKeywords`} />

      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content={t`title`} />
      <meta name="twitter:description" content={t`metaDescription`} />
      <meta name="twitter:image:src" content={metaImgUrl} />

      <meta property="og:title" content={t`title`} />
      <meta property="og:type" content="website" />
      <meta property="og:url" content={t`metaUrl`} />
      <meta property="og:description" content={t`metaDescription`} />
      <meta property="og:image" itemprop="image" content={metaImgUrl} />
      <meta name="facebook-domain-verification" content="axtvmzewktx5lqcc883x740dh8azef" />
    </Helmet>
  );
};
