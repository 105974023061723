import React from 'react';

import useIntlT from '../utils/useIntlT';

import styles from './add-meiyu-section.module.css';

import addMeiyuSvg from '../assets/add-meiyu.svg';
import gogolookLogo from '../assets/gogolook-logo.svg';

export default () => {
  const t = useIntlT();

  return (
    <div className={`${styles.section} bg-theme-1 md:pt-6 pb-4`}>
      <div className="max-w-screen-lg mx-auto md:px-8">
        <div className={`${styles.container}`}>
          <div
            className={`border-theme-default border-t-3 border-b-3 md:border-l-3 md:border-r-3 md:rounded bg-theme-2 flex justify-center items-center overflow-hidden md:overflow-visible`}
          >
            <img
              className="hidden md:block w-32 mb-6"
              alt={t`addMeiyuQRCodeAlt`}
              src={t`addMeiyuQRCode`}
            />
            <a
              href={t`addMeiyuHref`}
              className={`${styles.btn} mx-3 p-2 w-40 h-24 md:w-32 md:h-32 border-theme-default border-4 rounded bg-white hover:bg-theme-hover-2 active:bg-theme-active-2 text-center md:self-start flex flex-col justify-center items-center text-lg font-bold`}
            >
              {t('addMeiyuBtn', { insertBr: false })}
            </a>
            <img
              className={styles.meiyu}
              alt={t`addMeiyuAlt`}
              src={addMeiyuSvg}
            />
          </div>
          <a href={t`gogolookOfficialWebsite`} target="_blank" rel="noreferrer">
            <img
              className={`${styles.poweredByGogolook}`}
              alt={t`poweredByGogolook`}
              src={gogolookLogo}
            />
          </a>
        </div>
      </div>
    </div>
  );
};
